<template>
  <div class="container-fluid">
    <BarraCrud
      :botao-novo="true"
      :busca="busca"
      :breadcrumb="breadcrumb"
      :on-novo="onNovo"
      :on-filtrar="filtrar"
      :icone-customizado="iconeCustomizado"
      :nome-customizado="nomeCustomizado"
    />

    <IngredientesTable
      :empresas-status="empresasStatus"
      :botao-adicionar="botaoAdicionar"
      :add-on-save="addOnSave"
      @adicionou="adicionou"
      :content-class="contentClass"
      ref="ingredientesTable"
      :filtro="filtro"
      :tipo="tipo"
    />
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";
//import IngredientesTable from "@/components/Ingredientes/IngredientesTable.vue";
import IngredientesTable from "@/components/Ingredientes/IngredientesTable.vue";

export default {
  components: {
    BarraCrud,
    IngredientesTable,
  },
  props: {
    iconeCustomizado: { type: String, default: "" },
    nomeCustomizado: { type: String, default: "" },
    breadcrumb: { type: Boolean, default: true },
    busca: { type: Boolean, default: true },
    tipo: { type: String, default: "" },
    contentClass: { type: String, default: "" },
    botaoAdicionar: { type: Boolean, default: false },
    empresasStatus: { type: Boolean, default: true },
    addOnSave: { type: Boolean, deafult: false },
  },
  data() {
    return {
      filtro: "",
    };
  },
  methods: {
    filtrar(filtro) {
      this.filtro = filtro;
    },
    adicionou($event) {
      this.$emit("adicionou", $event);
      this.$bvModal.hide("modal-ingrediente");
    },
    onNovo() {
      // console.log(this);
      this.$refs.ingredientesTable.novo();
    },
  },
};
</script>
