<template>
  <div>
    <div
      v-for="grupo in grupoFiltrado"
      :key="`${grupoFiltrado.length}_${grupo}`"
      class="table"
    >
      <div v-if="loading" class="text-center">
        <b-spinner small /> Carregando...
      </div>
      <table name="table" class="table table-sm">
        <thead>
          <tr>
            <th colspan="99">
              <h4 class="text-left">
                {{ grupo }}
              </h4>
            </th>
          </tr>

          <tr>
            <th scope="col">Código</th>

            <th scope="col" class="text-left">Ingrediente</th>

            <th
              scope="col"
              class="empresasTable"
              v-for="empresa in empresas"
              :key="`empresa_${empresa.cod_empresa}`"
            >
              {{ empresasStatus ? empresa.nome : "" }}
            </th>

            <th scope="col" />
          </tr>
        </thead>

        <tbody
          v-for="ingrediente of ingredientesFiltrados"
          :key="ingrediente.cod_ingrediente"
          style="cursor: pointer"
        >
          <tr v-if="ingrediente.grupo == grupo && !loading">
            <th scope="row">
              {{ ingrediente.cod_ingrediente }}
            </th>
            <td class="text-left">
              {{ ingrediente.ingrediente }}
            </td>

            <td
              v-for="status in ingrediente.status"
              :key="`status_${status.cod_empresa}`"
            >
              <b-form-checkbox
                v-if="empresasStatus"
                v-model="status.status"
                @change="onChangeStatus(status, ingrediente)"
                :value="1"
                :unchecked-value="0"
                switch
              />
            </td>

            <td class="text-nowrap text-right">
              <b-button
                class="mr-3"
                v-if="botaoAdicionar"
                variant="primary"
                @click="adicionar(ingrediente)"
              >
                <i class="fas fa-plus" /> Adicionar
              </b-button>

              <b-button class="mr-3" @click="editar(ingrediente)">
                <i class="fas fa-edit" /> Editar
              </b-button>

              <b-button
                @click="excluir(ingrediente.cod_ingrediente)"
                variant="danger"
              >
                <i class="fas fa-trash"></i> Excluir</b-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal
      id="modal-ingredientes"
      hide-footer
      :content-class="`${contentClass ? contentClass : 'prodModal'}`"
      :title="`Editar/Criar Ingrediente`"
      no-fade
      @hidden="carOnClose"
    >
      <IngredientesForm
        :add-on-save="addOnSave"
        @adicionou="adicionouAoSalvar($event)"
        @salvou="carregarIngredientes()"
        :ingrediente-edit="ingredienteEdit"
        :position-unset="contentClass ? true : false"
        :empresa-status="empresasStatus"
      />
    </b-modal>
  </div>
</template>

<script>
import IngredientesLib from "../../libs/IngredientesLib";
import IngredientesForm from "@/components/Ingredientes/IngredientesForm.vue";

export default {
  components: {
    IngredientesForm,
  },
  data() {
    return {
      ingredientes: [],
      loading: false,
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      ingredienteEdit: {},
      ingredientesFiltrados: [],
      grupos: ["BORDAS", "ADICIONAIS", "INGREDIENTE"],
      empresas: [],
    };
  },
  props: {
    filtro: { type: String, required: false },
    tipo: { type: String, default: "" },
    contentClass: { type: String, default: "" },
    botaoAdicionar: { type: Boolean, default: false },
    addOnSave: { type: Boolean, default: false },
    empresasStatus: { type: Boolean, default: true },
  },
  methods: {
    carOnClose() {
      setTimeout(() => this.carregarIngredientes(), 1000);
    },
    novo() {
      this.ingredienteEdit = {};
      this.$bvModal.show("modal-ingredientes");
    },
    async carregarIngredientes() {
      this.loading = true;
      try {
        let result = await IngredientesLib.get();

        if (result) {
          this.ingredientes = result;
          console.log("res", result);
          this.$forceUpdate();
        } else {
          this.$swal.fire({
            toast: true,
            title:
              "Ops! Erro ao carregar os ingredientes :(. \n" +
              result.data.message,
            icon: "error",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        }
        //await this.$store.dispatch("auth/atualizaEmpresas");
      } catch (error) {
        this.$swal.fire({
          toast: true,
          title: "Ops! Erro ao carregar os ingredientes :(. \n" + error.message,
          icon: "error",
          position: "top-end",
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        });
      }

      this.filtrar();
      this.loading = false;
    },
    async adicionar(ingrediente) {
      this.$emit("adicionou", ingrediente);
    },
    async editar(ingrediente) {
      this.ingredienteEdit = ingrediente;
      await this.$nextTick(() => {
        this.$bvModal.show("modal-ingredientes");
      });
    },
    async excluir(ingrediente) {
      // console.log(ingrediente);
      if (ingrediente) {
        this.$swal
          .fire({
            title: "Você tem creteza que deseja excluir este ingrediente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.loading = true;
              const result = await IngredientesLib.del(ingrediente);
              console.log(result);
              if (result && result.success) {
                this.$swal.fire({
                  title: "Ingrediente excluído com sucesso!",
                  icon: "success",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });

                this.carregarIngredientes();
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir ingrediente!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
              this.loading = false;
            }
          });
      }
    },
    async carregaEmpesas() {
      if (this.empresasStatus) {
        this.$forceUpdate();
        await this.$nextTick();
        this.empresas = [...this.$store.state.empresas.empresas];
        this.empresas.push(this.$store.state.empresas);
        this.empresas = this.empresas.sort((a, b) =>
          a.cod_empresa > b.cod_empresa
            ? 1
            : a.cod_empresa < b.cod_empresa
            ? -1
            : 0
        );
      }
    },
    async adicionouAoSalvar(id) {
      // console.log('ksjdfklsdjfklsdjf')
      // console.log("ingrediente ao salvar, id", id);
      if (typeof id != "undefined" && id.toString() != "") {
        // console.log(this.ingredientes);
        await this.carregarIngredientes();
        let ingrediente = this.ingredientes.find(
          (ingred) => ingred.cod_ingrediente === id
        );
        // console.log("ingrediente ao salvar", ingrediente);
        if (ingrediente) {
          this.$emit("adicionou", ingrediente);
        } else {
          this.showToast(
            "error",
            "Ops, não conseguimos adicionar o ingrediente :/. Porfavor, tente novamente"
          );
        }
      }
    },
    filtrar() {
      this.ingredientesFiltrados = this.ingredientes.filter((x) => {
        return (
          String(x.ingrediente.toUpperCase()).indexOf(
            this.filtro.toUpperCase()
          ) != -1 ||
          String(x.cod_ingrediente.toString()).indexOf(this.filtro) != -1
        );
      });
    },
    async onChangeStatus(statusRef, ingredienteRef) {
      if (statusRef && ingredienteRef) {
        this.$set(statusRef, "_rowStatus", "updated");
        await this.salvar(ingredienteRef);
      }
    },
    async salvar(ingrediente) {
      try {
        let result = await IngredientesLib.store(ingrediente);
        // console.log(result);
        if (result) {
          this.showToast("success");
        }
      } catch (error) {
        console.log(error);
        this.showToast("error", "Erro ao atualizar status do ingrediente.");
      }
    },
  },
  async mounted() {
    this.$forceUpdate();
    await this.carregarIngredientes();
    await this.carregaEmpesas();
  },
  watch: {
    filtro() {
      this.filtrar();
    },
  },
  computed: {
    grupoFiltrado() {
      return this.grupos.filter((g) => {
        if (this.tipo) {
          return g === this.tipo;
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss">
.empresasTable {
  white-space: nowrap;
  font-size: 0.7rem;
}
</style>
