<template>
  <div>
    <h5 v-if="title">{{ title }}</h5>

    <b-table
      class="shadow-sm p-1 mb-2 bg-white"
      sticky-header="100px"
      :items="status"
      :fields="campos"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      small
      hover
      outlined
      stacked="sm"
      show-empty
      style="font-size:11px"
    >
      <template #empty>
        <h4 class="text-center">Nenhum dado para mostrar &#128543;</h4>
      </template>

      <template #cell(status)="row">
        <b-form-checkbox
          :unchecked-value="0"
          :value="1"
          v-model="row.item.status"
          @change="
            !row.item._rowStatus ? $set(row.item, '_rowStatus', 'updated') : {}
          "
          switch
        ></b-form-checkbox>
      </template>
    </b-table>
    <b-pagination
      size="sm"
      v-model="paginacao.pagina_atual"
      :total-rows="empresas.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Status" },
    status: { type: Array, default: () => [] },
  },
  data() {
    return {
      empresas: [],
      campos: [
        { key: "empresa", label: "Empresa", sortable: true },
        { key: "status", label: "Status", class: "text-center" },

        { key: "actions", label: "", class: "text-center" },
      ],
      paginacao: {
        registros_por_pagina: 9,
        pagina_atual: 1,
      },
      //   visible: true,
    }
  },
  mounted() {
    this.carregaEmpesas()
  },
  methods: {
    async carregaEmpesas() {
      this.$forceUpdate()
      await this.$nextTick()
      this.empresas = [...this.$store.state.empresas.empresas]
      this.empresas.push(this.$store.state.empresas)
      this.empresas = this.empresas.sort((a, b) =>
        a.cod_empresa > b.cod_empresa
          ? 1
          : a.cod_empresa < b.cod_empresa
          ? -1
          : 0
      )
      // console.log(
      //   "As empresas disponiveis: ",
      //   this.empresas,
      //   "Os status atuais: ",
      //   this.status
      // )
      if (this.status) {
        if (this.status.length != this.empresas.length) {
          const statusCodEmpresas = [
            ...this.status.map((stt) => {
              if (stt.cod_empresa) {
                return stt.cod_empresa
              }
            }),
          ]

          this.status.push(
            ...this.empresas
              .filter((e) =>
                !statusCodEmpresas.includes(e.cod_empresa) ? true : false
              )
              .map((emp) => {
                return {
                  cod_empresa: emp.cod_empresa,
                  empresa: emp.nome,
                  status: 0,
                  _rowStatus: "added",
                }
              })
          )
          this.status = this.status.sort((a, b) =>
            a.cod_empresa > b.cod_empresa
              ? 1
              : a.cod_empresa < b.cod_empresa
              ? -1
              : 0
          )
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// .fa-caret-down {
//   transform: rotate(0deg);
//   transition: all 0.1s ease-in-out;
// }

// .fa-caret-down.up {
//   transform: rotate(-180deg);
//   transition: all 0.1s ease-in-out;
// }
</style>
